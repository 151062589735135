navElements = document.getElementsByClassName('nav-link')
baseURI = document.baseURI

window.addEventListener("load", pageFullyLoaded, false);

function pageFullyLoaded(e) {
  for(var i = 0; i < navElements.length; i++) {
    if(baseURI.includes(navElements[i].href)) {
      navElements[i].classList.add('active')
    }
  }
}
